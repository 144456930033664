import React from 'react';
import Card from './Card';

const PricingCard = ({ pricing }) => (
  <Card className="p-8 mb-8 flex-1">
    <p className="text-3xl font-bold mb-3">{pricing.title}</p>
    <ul>
    {/* Left align */}
    {pricing.items.map(item => (
            <li key={item.value} className="flex-1 px-3 mb-5" style={{borderBottom: "1px solid black"}}>
              {item.value}
            </li>
          ))}
    </ul>
    <p>{pricing.price}</p>
  </Card>
);

export default PricingCard;
