export default [
  {
    title: 'BASIC',
    items:
      [
        {
          value: "Tailored strategic plan"
        },
        {
          value: "Account setup included"
        },
        {
          value: "2x Posts per week"
        },
        {
          value: "1x Image advert"
        },
        {
          value: "1x Social media platforms included (Instagram, Facebook etc.)"
        },
        {
          value: "Post scheduling"
        },
        {
          value: "Monthly content mapping"
        },
        {
          value: "Monthly strategy meeting"
        },
        {
          value: "Analytics reporting"
        },
        {
          value: "Monthly reporting"
        }
      ],
    price: 'R3 000.00',
  },
  {
    title: 'PREMIUM',
    items:
    [
      {
        value: "Tailored strategic plan"
      },
      {
        value: "Account setup included"
      },
      {
        value: "4x Posts per week"
      },
      {
        value: "1x Image advert"
      },
      {
        value: "2x Social media platforms included (Instagram, Facebook etc.)"
      },
      {
        value: "Post scheduling"
      },
      {
        value: "Monthly content mapping"
      },
      {
        value: "Monthly strategy meeting"
      },
      {
        value: "Analytics reporting"
      },
      {
        value: "Monthly reporting"
      }
    ],
    price: 'R5 000.00',
  },
  {
    title: 'DELUXE',
    items:
    [
      {
        value: "Tailored strategic plan"
      },
      {
        value: "Account setup included"
      },
      {
        value: "6x Posts per week"
      },
      {
        value: "1x Image advert"
      },
      {
        value: "1x Video Post"
      },
      {
        value: "2x Blog Post"
      },
      {
        value: "5x Social media platforms included (Instagram, Facebook etc.)"
      },
      {
        value: "Post scheduling"
      },
      {
        value: "Monthly content mapping"
      },
      {
        value: "Monthly strategy meeting"
      },
      {
        value: "Analytics reporting"
      },
      {
        value: "Monthly reporting"
      }
    ],
    price: 'R10 000.00',
  },
];
